// Import all of Bootstrap's JS
import * as bootstrap from "bootstrap";

// Import jQuery
import * as $ from "jquery";

// Import ScrollTrigger
import { ScrollTrigger } from "../plugins/ScrollTrigger.min";

$(document).ready(function () {
  // console.log("Write your global script here");

  // Global Entry Animation
  // Config to Disable All Warning, Change to true
  gsap.config({
    nullTargetWarn: false,
  });

  // Register Plugin Scroll Trigger
  gsap.registerPlugin(ScrollTrigger);

  // Config for Start Animation
  let startAnimate = {
    autoAlpha: 0,
    toRight: -100,
    toLeft: 100,
    start: "top 75%",
    end: "bottom 10%",
  };

  // Config for Finish Animation
  let finishAnimate = {
    autoAlpha: 1,
    yPercent: 0,
    xPercent: 0,
    duration: 0.75,
    stagger: 0.25,
    ease: "Power4.in",
  };

  gsap.utils.toArray("[data-animation]").forEach((div) => {
    let target = $(div).children().length
      ? $(div).children(":not([data-animation])")
      : div;
    // Needed Object Call
    // ScrollTrigger Settings
    let scrollSettings = {
      start: startAnimate.start,
      end: startAnimate.end,
    };

    // Finish Settings
    let animationFinish = {
      autoAlpha: finishAnimate.autoAlpha,
      duration: finishAnimate.duration,
      stagger: finishAnimate.stagger,
      ease: finishAnimate.ease,
    };

    switch ($(div).attr("data-animation")) {
      case "slide-down":
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
            yPercent: startAnimate.toRight,
          },
          {
            yPercent: finishAnimate.yPercent,
            animationFinish,
          }
        );
        break;

      case "slide-up":
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
            yPercent: startAnimate.toLeft,
          },
          {
            yPercent: finishAnimate.yPercent,
            animationFinish,
          }
        );
        break;

      case "slide-left":
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
            xPercent: startAnimate.toLeft,
          },
          {
            xPercent: finishAnimate.xPercent,
            animationFinish,
          }
        );
        break;

      case "slide-right":
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
            xPercent: startAnimate.toRight,
          },
          {
            xPercent: finishAnimate.xPercent,
            animationFinish,
          }
        );
        break;

      case "fade-in":
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
          },
          {
            animationFinish,
          }
        );
        break;

      default:
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
          },
          {
            animationFinish,
          }
        );
    }
  });

  // Navbar Js
  function navbar() {
    // scrolling navbar
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 50) {
        $(".js-navbar").css({ background: "transparent" });
      }
      if ($(this).scrollTop() > 80) {
        $(".js-navbar").css({
          height: "86px",
          background: "#1F404C",
        });
      }
    });

    // button open and close
    $(".js-navbar__menu").on("click", function () {
      $(this).css({ display: "none" });
      $(".js-navbar__close").css({ display: "block" });
      $(".navbar__links").css({ display: "flex" });
      $(".navbar__sign").css({ display: "flex" });
      $(".js-navbar__links").slideDown(500);
    });
    $(".js-navbar__close").on("click", function () {
      $(this).css({ display: "none" });
      $(".js-navbar__menu").css({ display: "block" });
      $(".navbar__links").css({ display: "none" });
      $(".navbar__sign").css({ display: "none" });
      $(".js-navbar__links").slideUp(500);
    });

    // Dropdown
    // --- Desktop
    $("#js-dropdown-navbar").click(function () {
      let menuSelect = $("#js-dropdown-menu");
      let navSelect = $("#js-dropdown-navbar");
      let conditionActive = navSelect.hasClass("active1");
      let mouseLeaveMenu = menuSelect.mouseleave(function () {
        console.log("Mouse Out of Menu");
        navSelect.removeClass("active1");
        $(this).slideUp({
          duration: 500,
        });
      });

      conditionActive | mouseLeaveMenu
        ? ($(this).removeClass("active1"),
          menuSelect.slideUp({
            duration: 500,
          }))
        : ($(this).addClass("active1"),
          menuSelect.slideDown({
            duration: 500,
            start: function () {
              $(this).css("display", "flex");
            },
          }));
    });

    // --- Mobile
    $("#js-dropdown").click(function () {
      $("#js-dropdown2, #js-dropdown-arrow2, .navbar__sign").toggleClass(
        "active"
      );
    });
  }

  function init() {
    navbar();
  }

  init();
});
